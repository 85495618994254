<template>
  <div class="newContentContainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title :isActive="true"></Title>
      <div v-if="data" class="newDesc" style="margin-top:80px">
        <div class="mianbaoxie">
          <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }" ><span style="color:#D70C0C;font-weight:400">首页</span></el-breadcrumb-item>
          <el-breadcrumb-item :to="{path:'/news'}"><span style="color:#D70C0C;font-weight:400">新闻动态</span></el-breadcrumb-item>
          <el-breadcrumb-item>新闻</el-breadcrumb-item>
        </el-breadcrumb>
        </div>
        <h4 class="descH4">{{data.NEWS_TITLE}}</h4>
        <div v-html="data.NEWS_CONTENT" class="newContent">{{data.NEWS_CONTENT}}</div>
        <span class="time">{{data.NEWS_TIME?data.NEWS_TIME.split(' ')[0]:''}}</span>
      </div>
      <Foot></Foot>
    </div>

    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
import { findNews } from '@/require/index.js'
export default {
  name: 'NewContent',
  data () {
    return {
      data: null
    }
  },
  methods: {
    getNew (id) {
      findNews(id).then(res => {
        if (res && res.data) {
          this.data = { ...res.data }
        }
      })
    },
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push({ path: '/moNews/moNewText', query: { id: this.$route.query.id } })
      }
    }
  },
  created () {
    this.getNew(this.$route.query.id)
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped lang='scss'>
.newContentContainer{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
body{
  font-family: "SF Pro SC","HanHei SC","SF Pro Text","Myriad Set Pro","SF Pro Icons","PingFang SC","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
}
.newDesc{
  width: 1200px;
   display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding-bottom: 60px;
}
.descH4{
 width: 1200px;
 font-size: 30px;
 text-align: center;
 color: #222;
}
.newContent{
  width: 1200px;
   &::v-deep {
    p {
      margin: 0px !important;
    }
  }

}

.time{
display: block;
width: 100%;
text-align: right;
}
.mianbaoxie{
  width: 1200px;
  margin-top: 20px;
}
</style>
