<template>
  <div class="journalismContainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>
      <div class="banner">
        <h4 style="font-size: 46px; font-weight: 600; padding: 105px 0 20px">
          新闻动态
        </h4>
        <p
          style="
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            padding-bottom: 40px;
          "
        >
          专业源于专注，专注产生价值
        </p>
        <button @click="$router.push('/freeTrial')">联系我们</button>
      </div>
      <div class="journalism">
        <ul class="journalismList">
          <li v-for="(item,index) in journalism" :key="index" @click="newHandle(item)">
            <div class="journalismListLeft">
                <img :src="item.src" alt="">
            </div>
            <div class="journalismListRight">
              <h5>{{item.title}}</h5>
              <p>{{item.desc}}</p>
              <div class="journalismListRightBottom">
                <img src="@/assets/journalism/eye.png" alt=""><span style="margin:0 16px 0 7px">{{item.see}}</span> | <img src="@/assets/journalism/timer.png" alt="" style="margin:0 7px 0 16px"> <span>{{item.time}}</span>
              </div>
            </div>
          </li>
        </ul>
        <div class="fenPage">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage1"
          :page-size="10"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      </div>
      <Foot></Foot>
    </div>

    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
import { getNewsList, updateNews } from '@/require/index.js'
export default {
  name: 'Journalism',
  data () {
    return {
      currentPage1: 1,
      journalism: [],
      total: 0,
      page: 1
    }
  },
  methods: {
    // 分页
    handleCurrentChange (val) {
      this.page = +val
      this.getNewsList(this.page)
    },
    // 获取列表
    getNewsList (page) {
      getNewsList(page, 10).then(res => {
        this.journalism = []
        this.total = res.total
        if (res.entitys) {
          res.entitys.forEach(item => {
            const src = item.NEWS_PICTURE ? (this.baseUrl + '/download?id=' + item.NEWS_PICTURE) : require('@/assets/journalism/news1.png')
            const obj = { title: item.NEWS_TITLE, desc: item.NEWS_INTRODUCTION, see: item.NEWS_CLICK, time: item.NEWS_TIME, src, id: item.ID, content: item.NEWS_CONTENT }
            this.journalism.push(obj)
          })
        }

        window.scrollTo(0, 0)
      })
    },
    // 转跳列表
    newHandle (item) {
      const lookNum = item.see ? (Number(item.see) + 1) : 1
      updateNews(item.id, lookNum).then(() => {
        this.$router.push({ path: '/news/newContent', query: { id: item.id } })
      })
    },
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moNews')
      }
    }

  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    this.getNewsList(this.page)
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped>
.journalismContainer {
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}
.channelShip {
  padding-top: 80px;
}
.banner {
  width: 100%;
  height: 460px;
  background: url("~@/assets/journalism/banner.png") center no-repeat;
  background-size:cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}
.journalism{
    position: relative;
    width: 100%;
    padding-bottom: 108px;
}
.journalismList {
  width: 1200px;
  margin: auto;
  padding-top: 40px;
}
.journalismList li {
  display: flex;
  justify-content: center;
  width: 1000px;
  border-bottom: 1px solid rgba(211, 218, 228, 0.5);
  margin: auto;
  padding: 40px 0;
}
.journalism .journalismList li:hover{
  cursor: pointer;
}
.journalism .journalismList li:hover h5{
  color: #D70C0C;
}
.journalismListLeft {
  width: 300px;
  height: 180px;
  margin-right: 60px;
}
.journalismListLeft img{
    width: 100%;
    height: 100%;
}
.journalismListRight {
  width: 640px;
  height: 180px;
  position: relative;
}

.fenPage {
    width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  left: 0;

}
.journalismListRight h5{
font-size: 20px;
font-weight: 600;
color: #222222;
margin-bottom: 20px;
overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.journalismListRight p{
font-size: 16px;
font-weight: 400;
color: #444444;
line-height: 24px;
overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.journalismListRightBottom{
color:#888;
display: flex;
align-items: center;
position: absolute;
bottom: 0;
left: 0;
font-size: 14px;
font-weight: 400;
}
.journalismListRightBottom img{
width: 16px;
}

</style>
